export const statusesContent = {
  IN_PROGRESS: {
    title: "Verification Company",
    content: "Your request is in progress. Please wait.",
    color: "#ffa500", // orange
  },
  NOT_FOUND_COMPANY: {
    title: "Your Company not found",
    content: "Please add your company details to work and receive your wallet",
    color: "#ffa500", // orange
  },
  VERIFICATION: {
    title: "Verification Required",
    content: "Please verify your account to continue.",
    color: "#ffcc00", // yellow
  },
  VALIDATED: {
    title: "Validated",
    content: "Your account has been successfully validated.",
    color: "#32cd32", // green
  },
  COMPANY_LOCATION_VERIFICATION: {
    title: "Company Location Verification",
    content: "Please verify your company’s location.",
    color: "#1e90ff", // blue
  },
  COMPANY_SIREN_VERIFICATION: {
    title: "Company SIREN Verification",
    content: "Please verify your company’s SIREN.",
    color: "#1e90ff", // blue
  },
  IDENTITY_VERIFICATION: {
    title: "Identity Verification",
    content: "Identity verification is required.",
    color: "#1e90ff", // blue
  },
  IBAN_VERIFICATION: {
    title: "IBAN Verification",
    content: "Please verify your IBAN details.",
    color: "#1e90ff", // blue
  },
  COMPANY_LOCATION_ACTION_REQUIRED: {
    title: "Action Required",
    content: "Action required: Verify your company’s location.",
    color: "#ff0000", // red
  },
  COMPANY_SIREN_ACTION_REQUIRED: {
    title: "Action Required",
    content: "Action required: Verify your company’s SIREN.",
    color: "#ff0000", // red
  },
  IDENTITY_ACTION_REQUIRED: {
    title: "Action Required",
    content: "Action required: Verify your identity.",
    color: "#ff0000", // red
  },
  IBAN_ACTION_REQUIRED: {
    title: "Action Required",
    content: "Action required: Verify your IBAN details.",
    color: "#ff0000", // red
  },
  COMPLETE_PROFIL_ACTION_REQUIRED: {
    title: "Action Required",
    content: "Complete all your profile details to apply",
    color: "#ff0000", // red
  },
  COMPLETE_PROFIL_ACTION_REQUIRED_PROFIL: {
    title: "You need to complete all profile details to ensure visibility on the platform and to be eligible to apply for job offers",
    color: "#ff0000", // red
  },
  SOLD_RECRUTER: {
    title: "Sold Recruter :",
    content: "Your total sales of offers is ",
    color: "#ff0000", // red
  },
  FOLDER_INPROGRESS: {
    title: "FOLDER INPROGRESS : ",
    content: "Your Folder in progress :  ",
    color: "#ff0000", // red
  },
};
