import { BlocShowJob } from "components/ModalITgalaxy/ModalApply/ModalApply.style";
import { useGetSharedCandidatQuery } from "../../redux/api/candidat/candidatApi";
import ViewCandidatProfil from "components/ComponnentProfilItems/Profils/Candidat/ViewCandidatProfil";
import Header from "components/Header/Header";
import FooterHome from "components/DashboardHome/FooterHome/FooterHome";
import { useParams } from "react-router-dom";

export default function ShareCandidat() {
  // Capitalized component name
  const { display } = useParams();
 
  const {
    data: candidateData,
    isLoading: isCandidateLoading,
    isError: isCandidateError,
  } = useGetSharedCandidatQuery(display);


  // Handle loading state for all queries
  if (isCandidateLoading) {
    return <div>Loading...</div>; // Display a loading state
  }

  // Handle error state for all queries
  if (isCandidateError ) {
    return <div>Error occurred while fetching data</div>; // Display an error message
  }

  // Handle the case when user data is not available
  if (!candidateData) {
    return <div>No user data available</div>;
  }

  return (
    <div style={{ width : "100%" , minHeight:"150vh" , backgroundColor: '#202124' }}> 
    <Header active={"FREELANCERS"} />
     <BlocShowJob style={{ marginBottom:"100px" ,  marginTop:"30px" ,  backgroundColor: '#202124' }}>
       <ViewCandidatProfil
        data={candidateData} // now it will use the correct data based on type
        editProfil={false}
        role={"CANDIDAT"} // Adjust role according to type
        />
       </BlocShowJob>
     <FooterHome />
    </div>
  );
}
